import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import SetupHostedExchangeApi from '@services/WebApiService/SetupHostedExchange';
import RestoreSkippedItemsApi from '@services/WebApiService/RestoreSkippedItemsService';
import JobStatusActivitiesApi from '@services/WebApiService/JobStatusActivities';
import OrganizationApi from '@services/WebApiService/OrganizationService';
import SdDomainApi from '@services/WebApiService/SdDomainServices';
import sliceReducer from './sliceReducer';

const reducer = {
  ...sliceReducer,
  [SetupHostedExchangeApi.reducerPath]: SetupHostedExchangeApi.reducer,
  [RestoreSkippedItemsApi.reducerPath]: RestoreSkippedItemsApi.reducer,
  [JobStatusActivitiesApi.reducerPath]: JobStatusActivitiesApi.reducer,
  [OrganizationApi.reducerPath]: OrganizationApi.reducer,
  [SdDomainApi.reducerPath]: SdDomainApi.reducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend()
      .concat(['production', 'test'].includes(process.env.NODE_ENV) ? [logger] : [])
      .concat(SetupHostedExchangeApi.middleware)
      .concat(RestoreSkippedItemsApi.middleware)
      .concat(JobStatusActivitiesApi.middleware)
      .concat(OrganizationApi.middleware)
      .concat(SdDomainApi.middleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
