import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import useCsvParser from '@hooks/useCsvParser';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  useAddHostedExchangeMutation,
  useValidateOrganizationMutation,
} from '@services/WebApiService/SetupHostedExchange';
import useSetupHostedExchageForm from './useSetupHostedExchageForm';

export default function useSetupHostedExchage({ onSuccessRequest, onFailedRequest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useSetupHostedExchageForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [addHostedExchangeMutation, { isLoading }] = useAddHostedExchangeMutation();
  const [validateOrganization, { isLoading: isValidating }] = useValidateOrganizationMutation();
  const [response, setResponse] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [validAdmin, setValidAdmin] = useState([]);
  const [validMailbox, setValidMailbox] = useState([]);

  const csvAdminParser = useCsvParser(['org_owner_id', 'exchange_ews_url', 'email', 'password']);
  const csvMailboxParser = useCsvParser(['org_owner_id', 'email']);

  const getPayloads = () => {
    if (['bulk_setup_admin', 'bulk_setup_mailbox'].includes(form.setupTypeAttributes.value)) {
      const parser =
        form.setupTypeAttributes.value === 'bulk_setup_admin' ? validAdmin : validMailbox;

      return parser.map((r) => {
        return {
          'org-owner-id': r?.org_owner_id,
          email: r?.email,
          'exchange-ews-url': r?.exchange_ews_url,
          password: r?.password,
          'setup-type': form.setupTypeAttributes.value.replace('bulk_', ''),
        };
      });
    }

    return [
      {
        'org-owner-id': form.organizationIdAttributes.value,
        email: form.emailAttributes.value,
        'exchange-ews-url': form.ewsUrlAttributes.value,
        password: form.passwordAttributes.value,
        'setup-type': form.setupTypeAttributes.value,
      },
    ];
  };

  const submitRequest = async () => {
    try {
      const payloads = getPayloads();
      const result = await addHostedExchangeMutation({
        accessToken: accessTokenSet,
        parameters: {
          'support-ticket': form.supportTicketAttributes.value,
          description: form.descriptionAttributes.value,
          'escalation-ticket': form.escalationTicketAttributes.value,
          payloads,
        },
      }).unwrap();
      setResponse(result);
    } catch (err) {
      setResponse({ error: err.data });
    }
  };

  const handleOnValidateData = (e) => {
    form.onValidateData(e);
  };

  useEffect(() => {
    if (form.isValid) {
      submitRequest();
    }
  }, [form.isValid]);

  const resetAll = () => {
    setResponse();
    form.resetForm();
    csvAdminParser.reset();
    csvMailboxParser.reset();
  };

  useEffect(() => {
    if (response?.['status-code'] === 200) {
      if (response?.data?.serviceTypeCode) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': response?.data?.serviceTypeCode,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t('hosted_exchange.setup.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('hosted_exchange.setup.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    } else if (response?.error) {
      onFailedRequest(response?.error?.message);
    }
    resetAll();
  }, [response]);

  const validateOrgOwnerId = async (orgOwnerId) => {
    try {
      const result = await validateOrganization({
        orgOwnerId,
        accessToken: accessTokenSet,
      }).unwrap();

      return result?.data[0];
    } catch (error) {
      return null;
    }
  };

  const getValidOrg = async (parserResult) => {
    return Promise.all(
      parserResult.map(async (r) => {
        const validOrg = await validateOrgOwnerId(r?.org_owner_id);
        if (validOrg) {
          return {
            org_owner_id: r?.org_owner_id,
            org_name: validOrg.name,
            email: r?.email,
            exchange_ews_url: r?.exchange_ews_url,
            password: r?.password,
          };
        }

        return null;
      })
    );
  };

  useEffect(() => {
    const processValidAdmins = async () => {
      if (!csvAdminParser.result.isValid) return;

      const validAdmins = await getValidOrg(csvAdminParser.result.results);
      setValidAdmin(validAdmins.filter(Boolean));

      form.csvAdminValidAttributes.handleChange(csvAdminParser.result.isValid);
      setDisabled(validAdmins.filter(Boolean).length === 0);
    };

    processValidAdmins();
  }, [csvAdminParser.result]);

  useEffect(() => {
    const processValidAdmins = async () => {
      if (!csvMailboxParser.result.isValid) return;

      const validMailboxs = await getValidOrg(csvMailboxParser.result.results);
      setValidMailbox(validMailboxs.filter(Boolean));

      form.csvMailboxValidAttributes.handleChange(csvMailboxParser.result.isValid);
      setDisabled(validMailboxs.filter(Boolean).length === 0);
    };

    processValidAdmins();
  }, [csvMailboxParser.result]);

  return {
    form,
    isLoading,
    validAdmin,
    validMailbox,
    parseAdminResult: csvAdminParser.result,
    parseMailboxResult: csvMailboxParser.result,
    handleOnValidateData,
    handleAdminOnFileUpload: csvAdminParser.handleOnFileUpload,
    handleMailboxOnFileUpload: csvMailboxParser.handleOnFileUpload,
    resetAll,
    disabled,
  };
}
