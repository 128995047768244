import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { resetCreateDomainBackupsState } from '@features/domainBackups/createDomainBackupsSlice';
import useValidation from './useValidation';
import useSdDomainBackups from './useSdDomainBackups';

function useForm(page) {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const {
    isLoading: isRequesting,
    requestResponse,
    handleCreateBackupsRequests,
  } = useSdDomainBackups();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    domainAttributes,
    resetForm,
    isValid,
    formData,
    errors,
    setErrors,
    validateAll,
  } = useValidation();

  const callApiHandler = () => {
    if (isValid) {
      dispatch(resetCreateDomainBackupsState());
      handleCreateBackupsRequests(formData);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    callApiHandler();
  };

  useEffect(() => {
    callApiHandler();
  }, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    domainAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    requestResponse,
    isRequesting,
  };
}

export default useForm;
