import React, { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  errorHandlerPath,
  loginPath,
  dashboardPath,
  formPagePath,
  listingPagePath,
  transferSubresellerPagePath,
  transferSubscriptionPagePath,
  mailBoxChangePath,
  dataIngestionPath,
  mailBoxSyncPath,
  viewExcludedMailBoxPath,
  viewAutoDiscoveryPath,
  dataIngestionListPath,
  bulkPurgePath,
  bulkPurgeListPath,
  registrationPath,
  domainBackupsPath,
  transferPath,
  reportsPagePath,
  listReportsPagePath,
  seatUsageReportsPagePath,
  purgeExternalAccountPath,
  purgeSharedDrivePath,
  showPartnerListPath,
  resellersListPath,
  numberOfUsersListPath,
  numberOfSubResellersListPath,
  numberOfOrganizationListPath,
  showOrganizationListPath,
  showPlansListPath,
  showUsersListPath,
  seatCountChangePath,
  monthlyBackupReportsPagePath,
  viewTenantAuthErrorsPath,
  organizationInformationPath,
  switchSPATypePath,
  m365TenantListPath,
  gwsTenantListPath,
  recreateBackupAdminPath,
  purgeM365TenantPath,
  changeBackupAdminPath,
  m365TenantDetailPath,
  userBackupInfoPath,
  purgeGWSTenantPath,
  userBackupDetailPath,
  gwsTenantDetailPath,
  hexTenantListPath,
  hexTenantDetailPath,
  mailBoxChangeDetailsPath,
  switchCredentialsDetailPath,
  switchCredentialsPath,
  showOrganizationUsersListPath,
  toolsRequestStatusPath,
  backupStatusesPath,
  downloadStatusesPath,
  invoicesListPath,
  sorMonitoringPath,
  sorMonitoringDetailPath,
  restoreStatusesPath,
  migrateStatusesPath,
  jobStatusActivitiesPath,
  sdDomainBackupsPath,
} from '@config/Routes/WebClientRoutes';
import {
  LoginPage,
  DashboardPage,
  FormPage,
  ListingPage,
  TransferSubresellerPage,
  TransferSubscriptionPage,
  MailboxChangePage,
  MailboxSyncChangePage,
  ViewExcludedMailBoxPage,
  RecreateBackupAdminPage,
} from '@pages';
import ViewAutoDiscoveryPage from '@pages/ViewAutoDiscovery';
import ErrorHandle from '@pages/HandleErrorPage';
import useLoginSSO from '@pages/LoginPage/pageHooks/useLoginSSO';
import {
  SwitchCredentialPage,
  BulkPurgePage,
  DataIngestion,
  DataIngestionListPage,
  RegistrationPage,
  PurgeM365TenantPage,
  PurgeGWSTenantPage,
  ChangeBackupAdminPage,
} from '@pages/index';
import useFeatureFlag from '@hooks/useFeatureFlag';
import BulkPurgeListPage from '@pages/BulkPurgeListPage';
import BaseSetting from '@config/BaseSetting';
import DomainBackups from '@pages/DomainBackupPage';
import TransferSubscriptionInitiatePage from '@pages/TransferSubscriptionInitiatePage';
import IngestionReportsPageSection from '@pages/ReportsPage/IngestionReportsPageSection';
import IngestionListReportsPageSection from '@pages/ReportsPage/IngestionListReportsPageSection';
import SeatUsageReportsPage from '@pages/SeatUsageReportsPage';
import PurgeExternalAccount from '@pages/PurgeExternalAccountPage';
import PurgeSharedDrive from '@pages/PurgeSharedDrivePage';
import TransferSubreseller from '@pages/TransferSubresellerPage/transferSubreseller';
import PartnerListPage from '@pages/PartnerListPage';
import ResellerTabs from '@ui/Sections/PartnerListPage/ResellerTabs';
import NumberOfUsersList from '@ui/Sections/PartnerListPage/NumberOfUsersList';
import NumberOfSubResellersList from '@ui/Sections/PartnerListPage/NumberOfSubResellersList';
import NumberOfOrganizationList from '@ui/Sections/PartnerListPage/NumberOfOrganizationList';
import OrganizationListPage from '@pages/OrganizationListPage';
import PlansListPage from '@pages/PlansListPage';
import UserListPage from '@pages/UsersListPage';
import SeatCountChange from '@pages/SeatCountChangePage';
import MonthlyBackupReportsPage from '@pages/MonthlyBackupReportPage';
import ViewTenantAuthErrorsPage from '@pages/ViewTenantAuthErrorsPage';
import OrganizationNamesTab from '@ui/Sections/OrganizationInformationPage/OrganizationNamesTab';
import SwitchSPATypePage from '@pages/SwitchSPATypePage';
import M365TenantPage from '@pages/TenantPage/M365Tenant';
import GWSTenantPage from '@pages/TenantPage/GWSTenant';
import M365TenantDetailsTab from '@ui/Sections/TenantDetailsPage/M365TenantDetailsTab';
import UserBackupPage from '@pages/UserBackupPage';
import UserBackupDetailPage from '@pages/UserBackupDetailPage';
import GWSTenantDetailsTab from '@ui/Sections/TenantDetailsPage/GWSTenantDetailsTab';
import HEXTenantPage from '@pages/TenantPage/HEXTenant';
import HEXTenantDetailsTab from '@ui/Sections/TenantDetailsPage/HEXTenantDetailsTab';
import DetailMailboxChangePage from '@pages/DetailMailboxChangePage';
import DetailSwitchCredentialPage from '@pages/DetailSwitchCredentialPage';
import OrganizationUserListPage from '@pages/OrganizationUsersListPage';
import ToolsRequestHistoryPage from '@pages/ToolsRequestHistoryPage';
import BackupStatusPage from '@pages/BackupStatusPage';
import InvoicesListPage from '@pages/InvoicesListPage';
import SORMonitorPage from '@pages/sorMonitoringPage';
import SORMonitoringDetailPage from '@pages/sorMonitoringDetailPage';
import DownloadStatusPage from '@pages/DownloadStatusPage';
import RestoreStatusPage from '@pages/RestoreStatusPage';
import MigrateStatusPage from '@pages/MigrateStatusPage';
import JobStatusActivitiesPage from '@pages/JobStatusActivitiesPage';
import SdDomainBackups from '@pages/SdDomainBackupPage';

export default function Navigation() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isUserSignedIn } = useSelector((stateList) => stateList.auth);
  const { getSilentTokenState } = useSelector((stateList) => stateList.silent);
  const { errorMessage, success } = getSilentTokenState;
  const { getAccessTokenState } = useSelector((stateList) => stateList.tokenAccess);
  const { tokenErrorMessage, currentAccountStatus } = getAccessTokenState;
  const { getRegions } = useSelector((stateList) => stateList.region);
  const { regionErrorMessage } = getRegions;
  const { getRefToken } = useSelector((stateList) => stateList.refToken);
  const { refreshErrorMessage } = getRefToken;
  const { getFeatureFlagState } = useSelector((stateList) => stateList.flags);
  const { featureFlagErrorMessage } = getFeatureFlagState;
  const { setSignedIn } = useLoginSSO();
  const { getFeatureFlagStateHandler } = useFeatureFlag();
  const { approval, active, suspended } = BaseSetting.accountStatusCode;

  const resopnseFlagState = () => {
    return Boolean(
      errorMessage ||
        tokenErrorMessage ||
        regionErrorMessage ||
        refreshErrorMessage ||
        featureFlagErrorMessage
    );
  };

  useEffect(() => {
    if (resopnseFlagState()) {
      setSignedIn(false);
      navigate(errorHandlerPath, { state: { errorPageState: true } });
    } else if (isAuthenticated && success && getAccessTokenState?.success) {
      if (!state?.userNameError && currentAccountStatus === active) setSignedIn(true);
      else setSignedIn(false);
    }
  }, [
    state?.userNameError,
    errorMessage,
    tokenErrorMessage,
    regionErrorMessage,
    isAuthenticated,
    refreshErrorMessage,
    featureFlagErrorMessage,
    success,
    getAccessTokenState?.success,
    currentAccountStatus,
  ]);

  const handleErrorTitleText = () => {
    return (
      (errorMessage && 'SSO Login Failed...') ||
      ((tokenErrorMessage ||
        regionErrorMessage ||
        refreshErrorMessage ||
        featureFlagErrorMessage) &&
        'Internal Server Error')
    );
  };

  const featureflag = getFeatureFlagStateHandler();

  const errorPageRoute = (
    <Route path={errorHandlerPath} element={<ErrorHandle titleText={handleErrorTitleText()} />} />
  );

  const getAccountStatusHandler = () => {
    return [approval, suspended].includes(currentAccountStatus) && isAuthenticated;
  };

  if (getAccountStatusHandler())
    return (
      <Routes>
        <Route
          path={registrationPath}
          element={<RegistrationPage currentAccountStatus={currentAccountStatus} />}
        />
        <Route key={registrationPath} path="*" element={<Navigate to={registrationPath} />} />
      </Routes>
    );

  return (
    <Routes>
      {isUserSignedIn ? (
        <>
          <Route key={loginPath} path={loginPath} element={<LoginPage />} />
          <Route key={formPagePath} path={formPagePath} element={<FormPage />} />
          <Route key={listingPagePath} path={listingPagePath} element={<ListingPage />} />
          {resopnseFlagState() && errorPageRoute}
          {success && getAccessTokenState?.success && (
            <Route key={dashboardPath} path={dashboardPath} element={<DashboardPage />} />
          )}
          {[
            <Route
              key={transferSubresellerPagePath}
              path={transferSubresellerPagePath}
              element={<TransferSubreseller />}
            />,
            <Route
              key={transferSubscriptionPagePath}
              path={transferSubscriptionPagePath}
              element={<TransferSubscriptionInitiatePage />}
            />,
            <Route key={transferPath} path={transferPath} element={<TransferSubscriptionPage />} />,
            <Route
              key={mailBoxChangePath}
              path={mailBoxChangePath}
              element={<MailboxChangePage />}
            />,
            <Route
              key={mailBoxSyncPath}
              path={mailBoxSyncPath}
              element={<MailboxSyncChangePage />}
            />,
            <Route
              key={viewExcludedMailBoxPath}
              path={viewExcludedMailBoxPath}
              element={<ViewExcludedMailBoxPage />}
            />,
            <Route
              key={viewAutoDiscoveryPath}
              path={viewAutoDiscoveryPath}
              element={<ViewAutoDiscoveryPage />}
            />,
            <Route
              key={viewTenantAuthErrorsPath}
              path={viewTenantAuthErrorsPath}
              element={<ViewTenantAuthErrorsPage />}
            />,
            <Route
              key={reportsPagePath}
              path={reportsPagePath}
              element={state?.row ? <IngestionReportsPageSection /> : ''}
            />,
            // <Route
            //   key={listReportsPagePath}
            //   path={listReportsPagePath}
            //   element={state?.row ? <IngestionListReportsPageSection /> : ''}
            // />,
            <Route key={dataIngestionPath} path={dataIngestionPath} element={<DataIngestion />} />,
            <Route
              key={dataIngestionListPath}
              path={dataIngestionListPath}
              element={state?.row ? <DataIngestionListPage /> : ''}
            />,
            <Route key={bulkPurgePath} path={bulkPurgePath} element={<BulkPurgePage />} />,
            <Route
              key={purgeExternalAccountPath}
              path={purgeExternalAccountPath}
              element={<PurgeExternalAccount />}
            />,
            <Route
              key={purgeSharedDrivePath}
              path={purgeSharedDrivePath}
              element={<PurgeSharedDrive />}
            />,
            <Route
              key={purgeGWSTenantPath}
              path={purgeGWSTenantPath}
              element={<PurgeGWSTenantPage />}
            />,
            <Route key={resellersListPath} path={resellersListPath} element={<ResellerTabs />} />,
            <Route
              key={numberOfUsersListPath}
              path={numberOfUsersListPath}
              element={<NumberOfUsersList />}
            />,
            <Route
              key={numberOfSubResellersListPath}
              path={numberOfSubResellersListPath}
              element={<NumberOfSubResellersList />}
            />,
            <Route
              key={numberOfOrganizationListPath}
              path={numberOfOrganizationListPath}
              element={<NumberOfOrganizationList />}
            />,
            <Route
              key={showPartnerListPath}
              path={showPartnerListPath}
              element={<PartnerListPage />}
            />,
            <Route
              key={showOrganizationListPath}
              path={showOrganizationListPath}
              element={<OrganizationListPage />}
            />,
            <Route key={showUsersListPath} path={showUsersListPath} element={<UserListPage />} />,
            <Route key={showPlansListPath} path={showPlansListPath} element={<PlansListPage />} />,
            <Route
              key={seatUsageReportsPagePath}
              path={seatUsageReportsPagePath}
              element={<SeatUsageReportsPage />}
            />,
            <Route
              key={monthlyBackupReportsPagePath}
              path={monthlyBackupReportsPagePath}
              element={<MonthlyBackupReportsPage />}
            />,
            <Route
              key={seatCountChangePath}
              path={seatCountChangePath}
              element={<SeatCountChange />}
            />,
            <Route
              key={bulkPurgeListPath}
              path={bulkPurgeListPath}
              element={state?.row ? <BulkPurgeListPage /> : <Navigate to={bulkPurgePath} />}
            />,
            <Route
              key={mailBoxChangeDetailsPath}
              path={mailBoxChangeDetailsPath}
              element={<DetailMailboxChangePage />}
            />,
            <Route
              key={organizationInformationPath}
              path={organizationInformationPath}
              element={<OrganizationNamesTab />}
            />,
            <Route
              key={switchSPATypePath}
              path={switchSPATypePath}
              element={<SwitchSPATypePage />}
            />,
            <Route
              key={m365TenantListPath}
              path={m365TenantListPath}
              element={<M365TenantPage />}
            />,
            <Route
              key={m365TenantDetailPath}
              path={m365TenantDetailPath}
              element={<M365TenantDetailsTab />}
            />,
            <Route key={gwsTenantListPath} path={gwsTenantListPath} element={<GWSTenantPage />} />,
            <Route
              key={gwsTenantDetailPath}
              path={gwsTenantDetailPath}
              element={<GWSTenantDetailsTab />}
            />,
            <Route
              key={hexTenantDetailPath}
              path={hexTenantDetailPath}
              element={<HEXTenantDetailsTab />}
            />,
            <Route
              key={recreateBackupAdminPath}
              path={recreateBackupAdminPath}
              element={<RecreateBackupAdminPage />}
            />,
            <Route
              key={recreateBackupAdminPath}
              path={recreateBackupAdminPath}
              element={<RecreateBackupAdminPage />}
            />,
            <Route
              key={purgeM365TenantPath}
              path={purgeM365TenantPath}
              element={<PurgeM365TenantPage />}
            />,
            <Route
              key={changeBackupAdminPath}
              path={changeBackupAdminPath}
              element={<ChangeBackupAdminPage />}
            />,
            <Route key={hexTenantListPath} path={hexTenantListPath} element={<HEXTenantPage />} />,
            <Route key={domainBackupsPath} path={domainBackupsPath} element={<DomainBackups />} />,
            <Route
              key={sdDomainBackupsPath}
              path={sdDomainBackupsPath}
              element={<SdDomainBackups />}
            />,
            <Route
              key={userBackupInfoPath}
              path={userBackupInfoPath}
              element={<UserBackupPage />}
            />,
            <Route
              key={userBackupDetailPath}
              path={userBackupDetailPath}
              element={<UserBackupDetailPage />}
            />,
            <Route
              key={backupStatusesPath}
              path={backupStatusesPath}
              element={<BackupStatusPage />}
            />,
            <Route
              key={restoreStatusesPath}
              path={restoreStatusesPath}
              element={<RestoreStatusPage />}
            />,
            <Route
              key={downloadStatusesPath}
              path={downloadStatusesPath}
              element={<DownloadStatusPage />}
            />,
            <Route
              key={migrateStatusesPath}
              path={migrateStatusesPath}
              element={<MigrateStatusPage />}
            />,
            <Route
              key={jobStatusActivitiesPath}
              path={jobStatusActivitiesPath}
              element={<JobStatusActivitiesPage />}
            />,
            <Route
              key={switchCredentialsDetailPath}
              path={switchCredentialsDetailPath}
              element={<DetailSwitchCredentialPage />}
            />,
            <Route
              key={switchCredentialsPath}
              path={switchCredentialsPath}
              element={<SwitchCredentialPage />}
            />,

            <Route
              key={showOrganizationUsersListPath}
              path={showOrganizationUsersListPath}
              element={<OrganizationUserListPage />}
            />,

            <Route
              key={toolsRequestStatusPath}
              path={toolsRequestStatusPath}
              element={<ToolsRequestHistoryPage />}
            />,
            <Route key={invoicesListPath} path={invoicesListPath} element={<InvoicesListPage />} />,
            <Route key={sorMonitoringPath} path={sorMonitoringPath} element={<SORMonitorPage />} />,
            <Route
              key={sorMonitoringDetailPath}
              path={sorMonitoringDetailPath}
              element={<SORMonitoringDetailPage />}
            />,
          ].filter(
            (route) => featureflag?.find((item) => item?.path === route?.props?.path)?.isActive
          )}

          {isAuthenticated && success && getAccessTokenState?.success && featureflag.length > 0 && (
            <Route key={dashboardPath} path="*" element={<Navigate to={dashboardPath} />} />
          )}
        </>
      ) : (
        <>
          <Route key={loginPath} path={loginPath} element={<LoginPage />} />
          {isAuthenticated && resopnseFlagState() && (
            <>
              {errorPageRoute}
              <Route key={errorHandlerPath} path="*" element={<Navigate to={errorHandlerPath} />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
}
