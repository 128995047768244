import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const SdDomainApi = createApi({
  reducerPath: 'sdDomainServices',
  baseQuery,
  endpoints: (builder) => ({
    initiateSdDomainList: builder.mutation({
      query: ({ accessToken, pageNumber, pageSize }) => {
        return {
          url: WebApiGeneralRoutes.apiV1Path(`/initiate-sd-domain`, {
            pageNumber,
            pageSize,
          }),
          method: 'GET',
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
    }),
    initiateSdDomainRequest: builder.mutation({
      query: ({ parameters, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/initiate-sd-domain'),
        method: 'POST',
        body: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
});

export const { useInitiateSdDomainListMutation, useInitiateSdDomainRequestMutation } = SdDomainApi;

export default SdDomainApi;
