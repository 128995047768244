import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import useLocalStorage from '@hooks/useLocalStorage';
import { submitSeatCountChangeAsync } from '@features/seatCountChange/seatCountChangeSlice';
import { constant } from '@config/BaseSetting';
import {
  getSeatCountChangeAsync,
  resetSeatCountChangeStatus,
} from '@features/seatCountChange/getSeatCountChangeSlice';
import useValidation from './useValidation';

function useForm() {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getSeatCountChangeState } = useSelector((state) => state.getSeatCountChangeState);

  const dispatch = useDispatch();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    changeOfSeatsAttributes,
    allowNfrAttributes,
    resetForm,
    isValid,
    formData,
    validateAll,
  } = useValidation();
  const previousPropsRef = useRef(formData);

  const submitHandler = (isFormValid) => {
    const previousProps = previousPropsRef.current;
    if (isFormValid && !isEqual(previousProps, formData)) {
      dispatch(
        submitSeatCountChangeAsync({
          accessToke: accessTokenSet,
          reset: true,
          parameters: formData,
        })
      );
      previousPropsRef.current = formData;
    }
  };

  const getSeatCountChangeRequestData = (data, setSeatCountChangeData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        ...payloadObj,
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    setSeatCountChangeData(modifiedData);
  };

  const getSeatCountChangeServices = (setSeatCountChangeData, setPagesSet) => {
    if (getSeatCountChangeState?.data?.length > 0) {
      setPagesSet(getSeatCountChangeState?.pagination);
      getSeatCountChangeRequestData(getSeatCountChangeState?.data, setSeatCountChangeData);
    }
  };

  const getSeatCountChangeAPIHandler = (pageParams) => {
    if (!getSeatCountChangeState?.fetching) {
      dispatch(resetSeatCountChangeStatus());
      dispatch(
        getSeatCountChangeAsync({
          accessToken: accessTokenSet,
          reset: true,
          pageNumber: pageParams,
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateAll();
    submitHandler(isFormValid);
  };

  useEffect(() => {}, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    changeOfSeatsAttributes,
    allowNfrAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    getSeatCountChangeServices,
    getSeatCountChangeAPIHandler,
  };
}

export default useForm;
