import React, { useState } from 'react';
import { constant } from '@config/BaseSetting';
import { getM365TenantAsync, resetM365TenantState } from '@features/m365Tenant/m365TenantSlice';
import {
  exportM365TenantAsync,
  resetExportM365TenantState,
} from '@features/m365Tenant/exportM365TenantSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';

export default function useTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  organizationOwnerId,
  pagination,
  adFilter,
  setAdFilter,
  setErrorMessageTextFiled,
  setErrorMessagePartnerTextFiled
) {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const { t } = useTranslation();
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [requestLicenseCheckerModal, setRequestLicenseCheckerModal] = useState(false);
  const [requestAutodiscoverModal, setRequestAutodiscoverModal] = useState(false);
  const [fetchParams, setFetchParams] = useState();
  const [invalidateServiceTokenModal, setInvalidateServiceTokenModal] = useState(false);
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const emptyFilterState = [
    { field: 'filter_is_main', value: 'true' },
    { field: 'filter_status', value: '' },
    { field: 'filter_mfa_enabled', value: '' },
    { field: 'filter_ad_sync_enabled', value: '' },
    { field: 'filter_enable_customer_app', value: '' },
    { field: 'filter_use_backup_admin', value: '' },
    { field: 'filter_non_backup_admin_flow', value: '' },
    { field: 'filter_deleted_at', value: 'false' },
    { field: 'filter_migrate_to_non_backup_admin_status', value: '' },
  ];

  const handleM365TenantAsync = (flag, resetFlag = false) => {
    dispatch(resetM365TenantState());

    const params = {
      fullTextSearch: resetFlag
        ? filterStates.searchFieldValueText
        : filterDataState.searchFieldValueText,
      searchById: resetFlag ? 0 : filterDataState.searchFieldValueID,
      organizationOwnerId: resetFlag ? organizationOwnerId : filterDataState.searchOrgId,
      partnerID: resetFlag ? 0 : filterDataState.searchPartnerId,
      orderBy: filterDataState.selectedSortColumn,
      filter: resetFlag ? emptyFilterState : adFilter,
    };

    dispatch(
      getM365TenantAsync({
        accessToken,
        reset: true,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        ...params,
      })
    );

    setFetchParams(params);
  };

  const handleExport = () => {
    dispatch(
      exportM365TenantAsync({
        accessToken,
        reset: true,
        ...fetchParams,
      })
    );
  };

  const handleResetExport = () => {
    dispatch(resetExportM365TenantState());
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueText: input,
        searchFieldValueID: input,
      }));
    } else {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: '',
        searchFieldValueText: input,
      }));
    }
  };
  const handleOnOwnerIDTextFiledChange = (value) => {
    const input = value;
    setFilterDataState((prevState) => ({
      ...prevState,
      searchOrgIdText: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchOrgId: input,
      }));
      setErrorMessageTextFiled('');
    } else if (input === '') {
      setErrorMessageTextFiled('');
      setFilterDataState((prevState) => ({
        ...prevState,
        searchOrgId: input,
      }));
    } else {
      setErrorMessageTextFiled('Invalid input type');
    }
  };
  const handleOnPartnerIDTextFiledChange = (value) => {
    const input = value;
    setFilterDataState((prevState) => ({
      ...prevState,
      searchPartnerIdText: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchPartnerId: input,
      }));
      setErrorMessagePartnerTextFiled('');
    } else if (input === '') {
      setErrorMessagePartnerTextFiled('');
      setFilterDataState((prevState) => ({
        ...prevState,
        searchPartnerId: input,
      }));
    } else {
      setErrorMessagePartnerTextFiled('Invalid input type');
    }
  };

  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selectedSortColumn: filterStates.selectedSortColumn,
      searchOrgId: filterStates.searchOrgId,
      searchOrgIdText: filterStates.searchOrgIdText,
      searchPartnerId: filterStates.searchPartnerId,
      searchPartnerIdText: filterStates.searchPartnerIdText,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setAdFilter(emptyFilterState);
    dispatch(resetM365TenantState());
    handleM365TenantAsync(true, true);
  };
  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      selectedSortColumn: filterStates.selectedSortColumn,
    }));
    setAdFilter(emptyFilterState);
  };
  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText, searchOrgId, searchPartnerId } =
      filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` ID = ${searchFieldValueID}`;
    }

    if (searchFieldValueText) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Credential ID/Tenant ID/Domain = '${searchFieldValueText}' `;
    }

    if (searchOrgId) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Organization Owner ID = '${searchOrgId}' `;
    }

    if (searchPartnerId) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Partner ID = '${searchPartnerId}', `;
    }

    adFilter.forEach((filter) => {
      if (filter.value) {
        if (filter.field === 'filter_is_main') {
          textData += 'Main Credential = ';
        } else if (filter.field === 'filter_status') {
          textData += 'Initial Setup Status = ';
        } else if (filter.field === 'filter_mfa_enabled') {
          textData += 'MFA Enabled = ';
        } else if (filter.field === 'filter_ad_sync_enabled') {
          textData += 'Ad Sync Department = ';
        } else if (filter.field === 'filter_enable_customer_app') {
          textData += 'Customer Tenant App = ';
        } else if (filter.field === 'filter_use_backup_admin') {
          textData += 'Backup Admin = ';
        } else if (filter.field === 'filter_non_backup_admin_flow') {
          textData += 'SPA flow = ';
        } else if (filter.field === 'filter_migrate_to_non_backup_admin_status') {
          textData += 'Migrated to SPA Status = ';
        } else if (filter.field === 'filter_deleted_at') {
          textData += 'Deleted At = ';
        }
        textData += filter.value;
        textData += ', ';
      }
    });

    if (textData === 'Matching ') {
      textData = '';
    }

    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };
  const getM365DataFromResponse = (data, setM365ListData) => {
    const dataObject = {
      0: 'Not Ready',
      1: 'In Progress',
      7: 'Completed',
      5: 'Error',
    };

    const dataObject1 = {
      0: 'Manual',
      1: 'Automatic',
    };

    const migrateStatusObj = {
      1: 'In Progress',
      7: 'Completed',
    };

    const dataObject3 = {
      True: 'Yes',
      File: 'No',
    };

    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        ...item,
        tenant: item.tenant ? item.tenant : '-',
        useBackupAdmin: item.useBackupAdmin,
        migrateToNonBackupAdminStatus: migrateStatusObj[item.migrateToNonBackupAdminStatus] || '',
        encryptedEmail: item.encryptedEmail ? item.encryptedEmail : '',
        status: dataObject[item.status],
        nonBackupAdminFlow: dataObject1[item.nonBackupAdminFlow],
      };
      return modifiedPayloadObj;
    });
    return setM365ListData(modifiedData);
  };

  const m365TenantHeaders = [
    {
      id: 1,
      label: 'Credential ID',
      columnName: 'id',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 2,
      label: 'Organization Owner ID',
      columnName: 'organizationOwnerId',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 3,
      label: 'Tenant ID',
      columnName: 'tenant',
      sx: { paddingLeft: 2 },
    },
    { label: 'Organization Name', columnName: 'orgName', sx: { paddingLeft: 2 } },
    {
      id: 4,
      label: 'Main App ID',
      columnName: 'azureAppId',
      sx: { paddingLeft: 2 },
    },
    { label: 'Partner Name', columnName: 'tenantPartnerName', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Partner ID',
      columnName: 'partnerId',
      sx: { paddingLeft: 2 },
      size: 150,
    },
    { label: 'Parent', columnName: 'tenantParent', sx: { paddingLeft: 2 } },
    {
      id: 6,
      label: 'Backup Admin Email',
      columnName: 'encryptedEmail',
      sx: { paddingLeft: 2 },
    },
    { id: 7, label: 'Main Credential', columnName: 'isMain', sx: { paddingLeft: 2 }, size: 100 },
    { id: 8, label: 'Created At', columnName: 'createdAt', sx: { paddingLeft: 2 } },
    { id: 9, label: 'Last Updated At', columnName: 'updatedAt', sx: { paddingLeft: 2 } },
    {
      id: 10,
      label: 'Azure App Version',
      columnName: 'azureAppVersion',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 11, label: 'Domain', columnName: 'domain', sx: { paddingLeft: 2 } },
    {
      id: 12,
      label: 'Initial Setup Status',
      columnName: 'status',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 13,
      label: 'Archiver',
      columnName: 'isArchiver',
      sx: { paddingLeft: 2 },
      size: 40,
    },
    {
      id: 14,
      label: 'Autodiscover Enabled At',
      columnName: 'lastAutodiscoverActivatedAt',
      sx: { paddingLeft: 2 },
    },
    { id: 15, label: 'MFA Enabled', columnName: 'mfaEnabled', sx: { paddingLeft: 2 }, size: 150 },
    {
      id: 16,
      label: 'Public Folder Backup',
      columnName: 'backupPublicFolder',
      sx: { paddingLeft: 2 },
    },
    { id: 17, label: 'Total Users in M365', columnName: 'totalUsers', sx: { paddingLeft: 2 } },
    {
      id: 18,
      label: 'Last Fetch Users At',
      columnName: 'lastScheduleMsGraphRequestAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 19,
      label: 'Last Added User to Backup At',
      columnName: 'lastAddedBackupEmailAt',
      sx: { paddingLeft: 2 },
    },
    { id: 20, label: 'Deleted At', columnName: 'deletedAt', sx: { paddingLeft: 2 } },
    {
      id: 21,
      label: 'Last Sync Users to M365 Throuh Sales ops At',
      columnName: 'lastMailboxSync',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    {
      id: 22,
      label: 'Ad Sync Department',
      columnName: 'adSyncDepartment',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 22,
      label: 'Ad Sync User Enabled At',
      columnName: 'adSyncUserEnabledAt',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 23,
      label: 'Last Ad Sync Run At',
      columnName: 'lastAdSyncAt',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    { id: 24, label: 'Total Site Numbers', columnName: 'totalSites', sx: { paddingLeft: 2 } },
    {
      id: 25,
      label: 'Last Added Site Backup At',
      columnName: 'lastAddedBackupSiteAt',
      sx: { paddingLeft: 2 },
      size: 150,
    },
    {
      id: 26,
      label: 'Sp Site Autodiscover Enabled At',
      columnName: 'spSiteAutodiscoverActivatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 27,
      label: 'Enable Customer Tenant App',
      columnName: 'enableCustomerApp',
      sx: { paddingLeft: 2 },
    },
    {
      id: 28,
      label: 'Last Sync Backup User At',
      columnName: 'lastSyncUsersAt',
      sx: { paddingLeft: 2 },
      size: 150,
    },
    {
      id: 29,
      label: 'Enabled Sync Users At',
      columnName: 'activateSyncUsersAt',
      sx: { paddingLeft: 2 },
    },
    { id: 30, label: 'Use Backup Admin', columnName: 'useBackupAdmin', sx: { paddingLeft: 2 } },
    {
      id: 31,
      label: 'SPA flow',
      columnName: 'nonBackupAdminFlow',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 32,
      label: 'Migrated to SPA At',
      columnName: 'migratedToNonBackupAdminAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 33,
      label: 'Migrated to SPA Status',
      columnName: 'migrateToNonBackupAdminStatus',
      sx: { paddingLeft: 2 },
    },
    {
      id: 34,
      label: 'Custom Role Creation Initiated At',
      columnName: 'customRoleCreationInitiatedAt',
      sx: { paddingLeft: 2 },
    },
    { id: 35, label: 'Endpoint', columnName: 'url', sx: { paddingLeft: 2 } },
    {
      id: 36,
      label: 'Ms Group Created At',
      columnName: 'msGroupCreatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 37,
      label: 'Ms Group Updated At',
      columnName: 'msGroupUpdatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 38,
      label: 'Available Licensed Users Number',
      columnName: 'availableEmailAccountCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 39,
      label: 'Available Not Licensed Users Number',
      columnName: 'availableEmailAccountUlCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 40,
      label: 'Added Users Number (only mailbox)',
      columnName: 'activeEmailAccountCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 41,
      label: 'Available Public Folder Number',
      columnName: 'availablePublicFolderCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 42,
      label: 'Added Public Folder Number',
      columnName: 'activePublicFolderCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 43,
      label: 'All Available Users Number',
      columnName: 'allBlacklistAccountCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 44,
      label: 'Excluded Users Numbers',
      columnName: 'allAvailableAccountCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 45,
      label: 'All Added Users Number (mailbox + public folder)',
      columnName: 'allActiveAccountCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 46,
      label: 'All Licensed Users Number',
      columnName: 'allLicensedCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 47,
      label: 'All Added Licensed Users Number',
      columnName: 'addedLicensedCount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 48,
      label: 'All Added Not Licensed Users Number',
      columnName: 'addedSharedMailboxCount',
      sx: { paddingLeft: 2 },
    },
  ];

  const showRunAutodiscoverModal = (row) => {
    const credential = row.original;
    credential.credentialType = 'm365';

    if (
      credential.deletedAt ||
      credential.isMain !== 'True' ||
      !credential.lastAutodiscoverActivatedAt ||
      credential.organizationDeactivatedSince
    ) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('tenant_page.run_autodiscover.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setRequestAutodiscoverModal(true);
      setSelectedCredential(credential);
    }
  };

  const showRunLicenseCheckerModal = (row) => {
    const credential = row.original;

    if (credential.deletedAt || credential.isMain !== 'True') {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('tenant_page.run_license_checker.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setRequestLicenseCheckerModal(true);
      setSelectedCredential(credential);
    }
  };

  const showInvalidateServiceTokenModal = (row) => {
    const credential = row.original;
    let errorMessage = null;

    if (
      credential.isMain !== 'True' ||
      credential.deletedAt ||
      credential.useBackupAdmin === 'True' ||
      credential.nonBackupAdminFlow !== 'Automatic' ||
      credential.exoAuthStatus === 7 ||
      credential.exoAuthStatus === 1 ||
      credential.organizationDeactivatedSince
    ) {
      errorMessage = t('tenant_page.invalidate_service_token.validations.main_validation');
    }

    if (errorMessage) {
      dispatch(
        showFixedAlert({
          descriptionHtml: errorMessage,
          severity: 'error',
        })
      );
    } else {
      setInvalidateServiceTokenModal(true);
      setSelectedCredential(credential);
    }
  };

  const menuActions = ({ row, closeMenu }) => {
    const menus = [];

    if (hasPermission(featureFlags.RunLicenseChecker)) {
      menus.push(
        <MenuItem
          key="1"
          onClick={() => {
            showRunLicenseCheckerModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('tenant_page.run_license_checker.title')}
        </MenuItem>
      );
    }

    if (hasPermission(featureFlags.InvalidateServiceToken)) {
      menus.push(
        <MenuItem
          key="2"
          onClick={() => {
            showInvalidateServiceTokenModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('tenant_page.invalidate_service_token.title')}
        </MenuItem>
      );
    }

    if (hasPermission(featureFlags.RunAutodiscover)) {
      menus.push(
        <MenuItem
          key="2"
          onClick={() => {
            showRunAutodiscoverModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('tenant_page.run_autodiscover.title')}
        </MenuItem>
      );
    }

    return menus;
  };

  const hasMenuActions =
    hasPermission(featureFlags.RunLicenseChecker) ||
    hasPermission(featureFlags.InvalidateServiceToken) ||
    hasPermission(featureFlags.RunAutodiscover);

  return {
    fetchParams,
    hasMenuActions,
    handleM365TenantAsync,
    handleExport,
    handleResetExport,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleOnHideAdvanceClick,
    handleStateUpdate,
    matchTextHandler,
    getM365DataFromResponse,
    handleUpdateFilters,
    m365TenantHeaders,
    handleOnOwnerIDTextFiledChange,
    handleOnPartnerIDTextFiledChange,
    selectedCredential,
    setSelectedCredential,
    menuActions,
    requestLicenseCheckerModal,
    setRequestLicenseCheckerModal,
    invalidateServiceTokenModal,
    setInvalidateServiceTokenModal,
    requestAutodiscoverModal,
    setRequestAutodiscoverModal,
    featureFlags,
  };
}
