import { useDispatch } from 'react-redux';
import { getDetailsOfDataIngestionAsync } from '@features/ingestion/serviceActions/fetchDetailsOfIngestionListAsync';
import { submitDataIngestionJobFormAsync } from '@features/ingestion/serviceActions/submitIngestionJobFormAsync';
import { reRunDataIngestionListItemAsync } from '@features/ingestion/serviceActions/reRunIngestionListAsync';
import { removeDataIngestionItemsAsync } from '@features/ingestion/serviceActions/removeDataIngestionItemAsync';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';

export default function useDetailsOfIngestionList() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const statusMap = {
    PartiallyCompleted: 'Completed with errors',
    Success: 'Completed',
  };

  const handleFetchDetailsOfIngestionList = (
    filterName,
    ingestionID,
    pageNumber,
    pageSize,
    selectedValue
  ) => {
    dispatch(
      getDetailsOfDataIngestionAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionID,
        pageNumber,
        pageSize,
        filterName,
        selectedValue,
      })
    );
  };

  const getFilterdDataFromResponse = (data, setDetailsOfIngestionsList) => {
    const modifiedData = data?.map((item) => {
      let payloadObj = JSON.parse(item.payload);
      payloadObj = payloadObj.JobPayload || payloadObj;
      const modifiedPayloadObj = {
        id: item?.id,
        'file-name': payloadObj['file-name'],
        's-type':
          (payloadObj['storage-type'] === 1101 && 'FTP') ||
          (payloadObj['storage-type'] === 1102 && 'S3TYPE') ||
          (payloadObj['storage-type'] === 1103 && 'PROSERV') ||
          (payloadObj['storage-type'] === 1104 && 'PROSERV_S3'),
        'created-on': item['created-on'],
        status: statusMap[item?.status] || item?.status,
        'status-description': item?.message,
        'email-account-id': payloadObj['email-upn'] || payloadObj['email-account-id'],
        'retry-count': item['retry-count'],
        'last-updated': item['last-updated-on'],
      };
      return modifiedPayloadObj;
    });
    return setDetailsOfIngestionsList(modifiedData);
  };

  const handleIngestionJobForm = (paramsList) => {
    const { parameters, ingestionJobID } = paramsList;
    dispatch(
      submitDataIngestionJobFormAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
        ingestionJobID,
      })
    );
  };

  const getFormData = (formData) => {
    const headerData = new FormData();

    headerData.append('emailAccount', formData.emailAddressName);
    headerData.append('fileName', formData.fileName);
    headerData.append('mappingFile', formData.mappingFile);
    headerData.append('organizationOwnerId', formData.organizationOwnerId);

    return headerData;
  };

  const checkValuesPresence = (formData) => {
    let flag = false;

    if (formData?.oneItemName === 'one') {
      flag = !!formData.emailAddressName && !!formData?.fileName && !!formData?.organizationOwnerId;
    } else if (formData?.multipleItemName === 'multi') {
      flag = !!formData?.mappingFile && !!formData?.organizationOwnerId;
    }

    return flag;
  };

  const handleIngestionJobReRunAsync = (paramsList) => {
    const { ingestionJobID, selectedItems } = paramsList;
    dispatch(
      reRunDataIngestionListItemAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionJobID,
        selectedItems,
      })
    );
  };

  const handleRemoveDataIngestionItemsAsync = (paramsList) => {
    const { ingestionJobID, selectedItems } = paramsList;
    dispatch(
      removeDataIngestionItemsAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionJobID,
        selectedItems,
      })
    );
  };

  return {
    handleFetchDetailsOfIngestionList,
    getFilterdDataFromResponse,
    handleIngestionJobForm,
    getFormData,
    checkValuesPresence,
    handleIngestionJobReRunAsync,
    handleRemoveDataIngestionItemsAsync,
  };
}
