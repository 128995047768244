import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FormControl } from '@mui/material';
import { ITBox, QboAlert, QboCheckbox, QboFormGroup, QboTextField } from '@ui/Components';
import { resetSeatCountChangeFormSubmit } from '@features/seatCountChange/seatCountChangeSlice';

function SeatCountChangeFormSection(props) {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    changeOfSeatsAttributes,
    allowNfrAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAstericRequired = true;

  const { submitSeatCountChangeForm } = useSelector((state) => state.submitSeatCountChangeState);

  const { errorMessage, statusCode } = submitSeatCountChangeForm;

  const stateResetHandler = () => {
    resetForm();
    dispatch(resetSeatCountChangeFormSubmit());
  };

  useEffect(() => {
    if (statusCode === 200) {
      setTimeout(() => {
        stateResetHandler();
      }, 3000);
    }
  }, [statusCode]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper ITBox_wrapper_modal" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(errorMessage || statusCode) && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {errorMessage || (statusCode === 200 && 'Successfully submitted')}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('mailbox_change.labels.support_ticket')}
          label={t('mailbox_change.labels.support_ticket')}
          fullWidth
          errorMessage={supportTicketAttributes.errorMessage}
          value={supportTicketAttributes.value}
          onChange={(e) => supportTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('mailbox_change.labels.escalation_ticket')}
          label={t('mailbox_change.labels.escalation_ticket')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
        />
        <QboTextField
          id="description"
          placeholder={t('mailbox_change.labels.description')}
          label={t('mailbox_change.labels.description')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
        />
        <QboTextField
          id="organization-owner-id"
          required={isAstericRequired}
          placeholder={t('mailbox_change.labels.organization_owner_id')}
          label={t('mailbox_change.labels.organization_owner_id')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          errorMessage={organizationOwnerIdAttributes.errorMessage}
          value={organizationOwnerIdAttributes.value}
          onChange={(e) => organizationOwnerIdAttributes.handleChange(e.currentTarget.value)}
        />
        <QboTextField
          id="change-of-seats"
          required={isAstericRequired}
          placeholder={t('seat_count_change.form.change-of-seats')}
          label={t('seat_count_change.form.change-of-seats')}
          type="number"
          inputProps={{ min: 0 }}
          fullWidth
          errorMessage={changeOfSeatsAttributes.errorMessage}
          value={changeOfSeatsAttributes.value}
          onChange={(e) => changeOfSeatsAttributes.handleChange(e.currentTarget.value)}
        />
        <QboCheckbox
          id="forceResetOAuth"
          label={t('seat_count_change.form.allow-nfr')}
          checked={allowNfrAttributes.value}
          onChange={(e) => {
            allowNfrAttributes.handleChange(e.currentTarget.checked);
          }}
        />
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <span style={{ height: '15px !important' }} className="QboTextField__warning-text">
            <WarningAmberIcon className="QboTextField__warning-icon" />
            {t('seat_count_change.form.note_message')}
          </span>
        </FormControl>
      </QboFormGroup>
    </ITBox>
  );
}

SeatCountChangeFormSection.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  organizationOwnerIdAttributes: PropTypes.object,
  changeOfSeatsAttributes: PropTypes.object,
  allowNfrAttributes: PropTypes.object,
  isValid: PropTypes.bool,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
};

SeatCountChangeFormSection.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  descriptionAttributes: {},
  organizationOwnerIdAttributes: {},
  changeOfSeatsAttributes: {},
  allowNfrAttributes: {},
  isValid: false,
  resetForm: () => {},
  formData: {},
  onSubmit: () => {},
};

export default SeatCountChangeFormSection;
